
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'normalize';
@use 'themes/default/imports';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$pats-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
    tertiary: mat.$blue-palette,
  ),
  density: (
    scale: 0,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  @include mat.all-component-themes($pats-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($pats-theme);

*,
*::before,
*::after {
  box-sizing: border-box;
}

.verticallyCentered {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.ptsNoSelect {
  -webkit-user-select: none !important;  /* Safari */
  -moz-user-select: none !important;  /* Firefox */
  -ms-user-select: none !important;  /* IE 10+ */
  user-select: none !important;  /* Non-prefixed version */
}

textarea, input {
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  border: var(--input-border);
  text-align: left;
  border-radius: var(--input-border-radius);
  padding: var(--input-padding);
  align-items: center;
  box-sizing: border-box;
  width: var(--input-default-width);
  &:hover {
    border-color: var(--hover-border-color);
  }
  &:focus {
    outline: none;
    border: var(--focus-border);
  }
}
input {
  height: var(--input-default-height);
}
textarea {
  height: var(--input-default-height-multiline)
}



button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: var(--input-border);
  border-radius: var(--input-border-radius);
  padding: var(--input-padding);
  text-align: center;
  cursor: pointer;
  font-size: var(--button-font-size, 1rem);
  box-sizing: border-box;
  width: var(--button-default-width, auto);
  height: var(--input-default-height);

  &:hover {
    border-color: var(--hover-border-color);
    background-color: var(--button-hover-background-color); 
  }

  &:focus {
    outline: none;
    border: var(--focus-border);
  }

  &:active {
    background-color: var(--button-active-background-color);
  }

  &:disabled {
    background-color: var(--button-disabled-background-color, #ccc);
    color: var(--button-disabled-text-color, #666);
    cursor: not-allowed;
  }
}





label {
  font-size: var(--label-text-size);
  font-weight: var(--label-weight);
}

.label-top {
  flex-direction: column;
  align-items: flex-start;

  label {
    margin-bottom: 5px;
  }
}

.label-left {
  flex-direction: row;
  align-items: center;

  label {
    margin-right: 3px;
  }
}

tbody tr:nth-child(odd) {
  background-color: var(--list-item-odd);
}

tbody tr:nth-child(even) {
  background-color: var(--list-item-even); 
}

.hoverableListItem:hover {
  background-color: var(--list-item-hover)
}

td {
  text-align: left;
}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply flex layout and height constraints globally */
:host {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

/* Ensure all direct child elements use constrained height */
:host > * {
  flex: 1 1 auto;
  height: 100%;
  min-height: 0;
  overflow: hidden;
}


.info-popup {
  position: absolute;
  padding: 5px;
  opacity: 0;
  background-color: var(--info-background-color);
  color: var(--info-text-color);
  border: 1px solid var(--info-border-color);
  border-radius: 5px;
  font-size: 12px;
  top: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  white-space: pre-wrap;
  display: inline-block;
  width: max-content;
  max-width: 600px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  z-index: 7000;
}

// Each page should get an "end of page spacer". This forces the page
// to allow users to scroll a bit past the end of the page in case they
// are trying to use a dropdown list that's at the bottom of the page, 
// causing it to be non-viewable.
.end-of-page-spacer {
  height: 250px;
  width: 100px;
}