[current-theme-mode="default-dark"] {
  --header-color: #007FAF;
  --header-font-color: white;
  --background-color: white;
  --searchbar-icon-color: #916868;
  --searchbar-watermark-color: #BBBBBB;
  
  --focus-color: #007bff;

  --list-item-even: #f6f6f6;
  --list-item-odd: white;
  --list-item-selected: #007bff;
  --list-item-selected-text: white;
  --list-item-hover: #f0f0f0;
  --list-item-hover-text: black;
  
  --info-text-color: blue;
  --info-border-color: #8888ff;
  --info-background-color: #ddddff;

  --hover-border-color: #888;

  --input-border-color: #bbbbbb;
  --input-background-color: white;
  --input-text-color: #333;

  --button-background-color: #007bff;
  --button-text-color: white;
  --button-hover-background-color: #0056b3;
  --button-active-background-color: #004085;
  --button-disabled-background-color: #ccc;
  --button-disabled-text-color: #666;

  --validator-border-color: red;
  --validator-popup-background-color: #ffdddd;
  --validator-popup-text-color: red;
  --validator-popup-border-color: darkred;
}