[current-theme="default"] {

  // ---------------------------------------------------------
  // Layout
  // ---------------------------------------------------------

  --focus-border: 2px solid var(--focus-color);

  // ------------------------------------
  // Header
  // ------------------------------------
  --header-height: 75px;
  --header-line-height: 1;

  --header-logo: url('/images/logos/ErtLogo.svg');
  --header-logo-padding: 0 0 0 5px;
  --header-logo-size: 50px;

  --header-title-padding: 0 0 0 15px;

  --header-font-family: "Segoe UI", ui-serif;
  --header-font-size: 40px;
  --header-font-weight: bold;

  --subheader-padding: 0px;
  --subheader-font-size: 40%;

  --header-searchbar-padding: 0 10% 10px 0;
  --header-searchbar-width: 350px;
  --header-searchbar-height: 30px;
  --horizontal-tab-height: 50px;

  // ------------------------------------
  // Footer
  // ------------------------------------
  --footer-Height: 20px;




  // ---------------------------------------------------------
  // Components
  // ---------------------------------------------------------
  --label-weight: 500;
  --label-font-size: 14px;
  --label-right-padding: 4px;

  --textbox-weight: normal;
  --textbox-font-size: 14px;

  --input-default-height: 35px;
  --input-default-height-multiline: 100px;
  --input-default-width: 200px;
  --input-border: 1px solid var(--input-border-color);
  --input-border-radius: 4px;
  --input-padding: 0 8px;

  --info-border: 1px solid var(--info-border-color);

  // ------------------------------------
  // Search Bars
  // ------------------------------------
  --searchbar-corner-rounding: 8px;
  --searchbar-font-family: "Segoe UI", ui-serif;
  --searchbar-font-size: 20px;
  --searchbar-font-weight: 450;
  --searchbar-text-vertical-offset: -1px;
  --searchbar-icon-size: 20px;
  --searchbar-icon-margin: 0 0 0 5px;
}